<template>
  <div class="svg_holder" :class="[color, size]">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 389.01 573.93"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M355.82,86.38C308.17,19,242.46-10,160.69,3.06,102.33,12.39,57.89,44.84,27.28,95.19,0,140.05-5.21,188.86,4.56,239.81,13.91,288.6,33.37,333.6,56.68,377c31.85,59.34,69.93,114.55,112.49,166.7,8.26,10.12,16.74,20.06,25.22,30.2,7.5-8.84,14.66-17.19,21.71-25.62,3.85-4.6,7.53-9.34,11.27-14,46.87-58.81,88.44-121,120.9-189,19.24-40.33,34.17-82.06,39.26-126.79C393,170.61,383.65,125.75,355.82,86.38Zm-5.24,142.44c-6.15,37-19.48,71.57-35.79,105.05C282.38,400.38,240.84,461,194,519.32c-17.07-23.11-34.49-45.62-50.74-69-29.75-42.7-56.44-87.25-77.55-134.93-13.94-31.48-24.92-63.91-29-98.3-6.19-51.77,7.91-97.34,44.52-134.52,33.2-33.73,74.35-50.49,121.9-47,67.75,5,114.49,40.23,140.31,102.8C355.42,167.57,355.69,198.07,350.58,228.82Z"/><path d="M194.89,93.53C136.7,93,89.23,140.19,89,198.69A105.48,105.48,0,0,0,194.35,304.47c58,.23,105.21-46.74,105.63-105S253.66,94,194.89,93.53Zm-.31,175.93c-38.24.15-70.31-31.92-70.39-70.39s31.63-70.35,70.17-70.4,70.38,31.62,70.45,70.19C264.87,237.21,232.94,269.32,194.58,269.46Z"/></g></g></svg>
  </div>
</template>

<script>
export default {
  name: 'IconLocation',
  props: ['size', 'color']
}
</script>

<style scoped>
  .svg_holder {
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
    -webkit-justify-content:center;
    justify-content:center;
  }
</style>
